import { useEffect } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Breadcrumb } from "react-bootstrap";
import React from "react";
import Accordion from "react-bootstrap/Accordion";

function Faqs() {
  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className="py-3 px-3 px-md-5">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>FAQs</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="border p-3 p-md-5 rounded bg-white">
          <h4 className="text-capitalize mb-4" data-aos="fade-up" data-aos-delay="100">
            Frequently asked questions
          </h4>
          <Accordion data-aos="fade-up" data-aos-delay="200">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What sizes do you offer?</Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                We offer a wide range of sizes to accommodate all golfers, from XS to XXXL. Please
                refer to our size chart for detailed measurements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Do you offer customization or personalization services?
              </Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                Yes, we offer customization or personalization services.{" "}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Do you offer gift cards?</Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                Yes, we offer gift cards for purchase. They make the perfect gift for any golf
                enthusiast and giveaways to any golf tournament. Gift cards are available in various
                denominations and can be redeemed through partner ProShops. Please refer to the list
                of partner Pro Shops.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Are your golf apparel items suitable for both men and women?
              </Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                Yes, we offer a diverse selection of golf apparel tailored for both men and women.
                Each item is designed with attention to fit, style, and performance for respective
                genders.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How can I stay updated on new arrivals and special collections of golf apparel?
              </Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                You can follow us on social media platforms such as Facebook, where we regularly
                post updates on new arrivals, special collections, promotions, and more.
                Specifically, you can find us on our Facebook page named K&G Golf Fashion Co., Ltd,
                where we share the latest news and releases related to our products. Additionally,
                regularly visiting our website will keep you informed about the latest offerings.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>What materials are used in your golf apparel?</Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                We typically incorporate a variety of materials to provide comfort, performance, and
                style on the course. Materials used were Polyester and Spandex.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                Do you offer any warranty or guarantees on your golf apparel?
              </Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                We want you to be completely satisfied with your purchase. If for any reason you are
                not happy with your order, we accept returns within 30 days of purchase. Please
                ensure the items are unworn, unwashed, and in their original condition with tags
                attached.{" "}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                Do you offer any discounts or promotions for bulk purchases of golf apparel?
              </Accordion.Header>
              <Accordion.Body className="accordion-body py-4">
                Yes, we do offer discounts and promotions. Terms and conditions apply.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Faqs;

import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { Col, Row } from "react-bootstrap";

function Index() {
  const [productData, setProductData] = useState([]);
  const [activeList, setActiveList] = useState("New Arrivals");
  const [isMobile, setIsMobile] = useState(false);

  const [carouselIndex, setCarouselIndex] = useState(0); // State to track active index

  const handleSelect = (selectedIndex, e) => {
    setCarouselIndex(selectedIndex); // Update active index
  };

  useEffect(() => {
    fetch("/data/product.json") // Adjust the path as necessary
      .then((response) => response.json())
      .then((data) => setProductData(data))
      .catch((error) => console.error("Error fetching product data:", error));

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  const filteredProducts =
    activeList === "New Arrivals"
      ? productData.filter((product) => product.status === "New")
      : productData.filter((product) => product.status === "Trends");

  return (
    <div className="w-100 m-0">
      {/* Header and Navigation */}
      <Header />
      {/* Main Content */}
      <Carousel
        fade
        className={`custom-carousel w-100 ${carouselIndex === 0 ? "carousel-dark" : ""}`}
        activeIndex={carouselIndex}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <img
            src={isMobile ? "/images/Banner_2_Mobile.jpg" : "/images/Banner_2.jpg"}
            alt="K&G Banner"
            className="img-fluid object-fit-cover"
          ></img>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={isMobile ? "/images/Banner_3_Mobile.jpg" : "/images/Banner_3.jpg"}
            alt="K&G Banner"
            className="img-fluid object-fit-cover"
          ></img>
        </Carousel.Item>
      </Carousel>

      <Container className="py-2 mx-auto w-100">
        <h1
          data-aos="fade-up"
          className="text-center text-uppercase fs-5 py-3 fw-bolder text-decoration-underline link-offset-3"
        >
          Find Your Style
        </h1>
        <div className="d-flex w-100 flex-wrap flex-row">
          <div className="accessory-white " data-aos="flip-up">
            <img
              src="/images/products/FA201.png"
              alt="Accessories Caps"
              className="img-fluid h-100"
            />
          </div>

          <div
            className="d-flex bg-black flex-wrap justify-content-center accessory-black p-3"
            data-aos="flip-down"
          >
            <Container className="d-flex flex-row flex-wrap align-content-end justify-content-center p-1">
              <h1 className="text-uppercase fs-2 text-white fw-bold text-decoration-underline link-offset-3">
                Cap Collection
              </h1>
            </Container>
            <Container className="d-flex flex-row flex-wrap align-content-start justify-content-center p-1">
              <Link
                to="/products/accessories"
                className="bg-white py-2 px-4 text-black link-offset-3 fw-bold fs-6 text-uppercase border border-1 border-black accessories-link"
              >
                see more
              </Link>
            </Container>
          </div>
        </div>
        <div className="d-flex w-100 flex-wrap flex-row">
          <div
            data-aos="flip-up"
            className="d-flex bg-black flex-wrap justify-content-center accessory-black p-3 order-2 order-md-1"
          >
            <Container className="d-flex flex-row flex-wrap align-content-end justify-content-center p-1">
              <h1 className="text-uppercase fs-2 m-0 text-white fw-bold text-decoration-underline link-offset-3">
                Belt Collection
              </h1>
            </Container>
            <div className="container d-flex flex-row flex-wrap align-content-start justify-content-center p-3">
              <Container className="d-flex flex-row flex-wrap align-content-end justify-content-center p-1">
                <Link
                  to="/products/accessories"
                  className="bg-white py-2 px-4 text-black link-offset-3 fw-bold fs-6 text-uppercase border border-1 border-black accessories-link"
                >
                  see more
                </Link>
              </Container>
            </div>
          </div>
          <div data-aos="flip-down" className="accessory-white order-1 order-md-2">
            <img
              src="/images/products/EJ2.jpg"
              alt="Accessories Belts"
              className="img-fluid h-100"
            />
          </div>
        </div>
        <div className="d-flex w-100 flex-wrap flex-row">
          <div data-aos="flip-up" className="accessory-white">
            <img
              src="/images/products/EL4.jpg"
              alt="Accessories Others"
              className="img-fluid h-100"
            />
          </div>
          <div
            data-aos="flip-down"
            className="d-flex bg-black flex-wrap justify-content-center accessory-black p-3"
          >
            <Container className="d-flex flex-row flex-wrap align-content-end justify-content-center p-1">
              <h1 className="text-uppercase fs-2 text-white fw-bold text-decoration-underline link-offset-3">
                Others
              </h1>
            </Container>
            <Container className="d-flex flex-row flex-wrap align-content-start justify-content-center p-1">
              <Link
                to="/products/accessories"
                className="bg-white py-2 px-4 text-black link-offset-3 fw-bold fs-6 text-uppercase border border-1 border-black accessories-link"
              >
                see more
              </Link>
            </Container>
          </div>
        </div>
      </Container>

      <Container fluid className="bg-black p-5 my-3">
        <h1 className="text-white fs-3 text-center text-uppercase" data-aos="zoom-in">
          Discover the latest trends in KNG Golf products! Our new arrivals combine unmatched
          quality with innovative design, ensuring you look and feel your best on the course.
          Explore our collection today!
        </h1>
      </Container>

      <Container fluid className="py-2 mx-auto">
        <Container
          fluid
          data-aos="fade-up"
          className="d-flex justify-content-center justify-content-md-start"
        >
          <Button
            className={`selector-button ${
              activeList === "New Arrivals" ? "selector-button-active" : ""
            }`}
            onClick={() => setActiveList("New Arrivals")}
          >
            New Arrivals
          </Button>
          <Button
            className={`selector-button ${
              activeList === "Trending" ? "selector-button-active" : ""
            }`}
            onClick={() => setActiveList("Trending")}
          >
            Trends
          </Button>
        </Container>
        <Container fluid>
          <Row>
            {filteredProducts.map((product) => (
              <Col
                className="col-6 col-md-6 col-lg-3 p-2"
                data-aos="fade-up"
                data-aos-delay={100}
                key={product.id}
              >
                <Link
                  to={`/products/${product.category}/${product.id}`}
                  className="product-card-link text-decoration-none"
                >
                  <Card className="rounded-0 border">
                    <Card.Header className="d-flex justify-content-center border-0 rounded-0 p-0">
                      <Card.Img
                        variant="top"
                        src={`/images/products/list/${product.images[0]}`}
                        className="img-fluid h-auto"
                        alt={`${product.name}`}
                      />
                    </Card.Header>
                    <Card.Body>
                      <Card.Title className="product-name my-0">{product.name}</Card.Title>
                      <Card.Text className="product-text my-0">{product.type}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="border-0 d-flex justify-content-center bg-white">
                      <Link
                        to={`/products/${product.category}/${product.id}`}
                        className="product-button w-100 text-center p-1 rounded-0 text-lowercase text-decoration-none"
                      >
                        View
                      </Link>
                    </Card.Footer>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>

      <Container fluid className="bg-black p-5 my-3">
        <h1 className="text-white fs-3 text-center text-uppercase" data-aos="zoom-in">
          Elevate your game with KNG Golf’s Boston bags, stand bags, and versatile pouches. Stylish
          and functional, each piece is designed for every golfer’s needs. Shop now and gear up for
          success!
        </h1>
      </Container>

      <Container className="py-5 mx-auto" data-aos="fade-up">
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <div className="bg-white d-flex flex-wrap w-100">
              <div className="bag-item d-flex justify-content-center">
                <img className="w-100 h-auto" src="images/standbag.jpg" alt="Stand bag" />
              </div>
              <div className="bag-item d-flex justify-content-center align-items-center flex-wrap p-5">
                <div className="w-100 h-50 d-flex align-items-end">
                  <h1 className="text-black text-uppercase fw-bold">
                    Stand Out on the Course with Our Premium Stand Bag Collection
                  </h1>
                </div>
                <div className="w-100 h-50 d-flex align-items-start">
                  <Link
                    to="/products/bags"
                    className="bags-link bg-black px-4 py-2 text-white link-offset-3 fw-bold text-uppercase border border-1 border-black"
                  >
                    Shop
                  </Link>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-white d-flex flex-wrap w-100">
              <div className="bag-item d-flex justify-content-center">
                <img className="w-100 h-auto" src="images/bostonbag.png" alt="Boston Bag" />
              </div>
              <div className="bag-item d-flex justify-content-center align-items-center flex-wrap p-5">
                <div className="w-100 h-50 d-flex align-items-end">
                  <h1 className="text-black text-uppercase fw-bold">
                    Travel in Style with Our Premium Boston Bag Collection
                  </h1>
                </div>
                <div className="w-100 h-50 d-flex align-items-start">
                  <Link
                    to="/products/bags"
                    className="bags-link bg-black px-4 py-2 text-white link-offset-3 fw-bold text-uppercase border border-1 border-black"
                  >
                    Shop
                  </Link>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="bg-white d-flex flex-wrap w-100">
              <div className="bag-item d-flex justify-content-center">
                <img className="w-100 h-auto" src="images/pouch.jpg" alt="Pouch" />
              </div>
              <div className="bag-item d-flex justify-content-center align-items-center flex-wrap p-5">
                <div className="w-100 h-50 d-flex align-items-end">
                  <h1 className="text-black text-uppercase fw-bold">
                    Keep Your Essentials Close at Hand with Our Golf Pouches
                  </h1>
                </div>
                <div className="w-100 h-50 d-flex align-items-start">
                  <Link
                    to="/products/bags"
                    className="bags-link bg-black px-4 py-2 text-white link-offset-3 fw-bold text-uppercase border border-1 border-black"
                  >
                    Shop
                  </Link>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </Container>

      <Footer />
      <ScrollToTop />
    </div>
  );
}
export default Index;

import { useEffect } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Breadcrumb } from "react-bootstrap";
import React from "react";
function Terms() {
  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className="py-3 px-3 px-md-5">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Terms and Conditions</Breadcrumb.Item>
        </Breadcrumb>
        <Container className="border p-3 p-md-5 rounded bg-white">
          <h4 data-aos="fade-up">Terms and Conditions</h4>
          <Container fluid data-aos="fade-up" data-aos-delay="100">
            <h6 className="mt-4">Return Policy for K&G Golf Fashion Co., Ltd Apparel:</h6>
            <p className="justify">
              At K&G Golf Fashion Co., Ltd, we strive to provide our customers with high-quality
              golf apparel that exceeds expectations. However, if you're not entirely satisfied with
              your purchase, we're here to help.
            </p>
            <h6>Eligibility:</h6>
            <p className="justify">
              To be eligible for a return, your item must be unused, unworn, and in the same
              condition that you received it. Returns must be initiated within 30 days of the
              original purchase date.
            </p>
            <h6>Refunds:</h6>
            <p className="justify">
              Once your return is received and inspected, we will send you an email to notify you
              that we have received your returned item. We will also notify you of the approval or
              rejection of your refund. If your return is approved, your refund will be processed,
              and a credit will automatically be applied to your original method of payment within
              5-7 days.
            </p>
            <h6>Exchanges:</h6>
            <p className="justify">
              If you need to exchange an item for a different size or color, please contact us to
              arrange the exchange. Exchanges are subject to availability.
            </p>
            <h6>Shipping:</h6>
            <p className="justify">
              Customers are responsible for paying the shipping costs associated with returning
              their item. Shipping costs are non-refundable.
            </p>
            <h6>How to Initiate a Return:</h6>
            <p className="justify">
              To initiate a return, please contact our customer service team at mycie@golftime.ph /
              +63 995 396 4667. Our team will provide you with further instructions on how to
              proceed with your return.
            </p>
            <h6>Damaged or Defective Items:</h6>
            <p className="justify">
              If you receive a damaged or defective item, please contact us immediately so that we
              can resolve the issue promptly.
            </p>
            <h6>Final Sale Items:</h6>
            <p className="justify">
              Please note that final sale items are not eligible for return or exchange unless they
              are damaged or defective upon receipt.
            </p>
            <h6>Contact Us:</h6>
            <p className="justify">
              If you have any questions about our return policy or need assistance with a return,
              please don't hesitate to contact us at mycie@golftime.ph / +63 995 396 4667. Thank you
              for choosing K&G Golf Fashion Co., Ltd. We appreciate your business and are committed
              to ensuring your satisfaction with every purchase.
            </p>
          </Container>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}

export default Terms;

import React, { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa6";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`rounded-circle position-fixed z-3 scroll-to-top-button ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <FaChevronUp />
    </button>
  );
}

export default ScrollToTopButton;

import { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Col, Container, Row, Breadcrumb, Form, Button, Toast } from "react-bootstrap";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FaFax, FaPhone, FaLocationDot, FaEnvelope } from "react-icons/fa6";

function Contact() {
  const form = useRef();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0knezyl", "template_pygrzed", form.current, {
        publicKey: "0OARZqf9ukJo6JCQP",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setShowSuccessToast(true);
          setTimeout(() => {
            setShowSuccessToast(false);
          }, 3000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setShowErrorToast(true);
          setTimeout(() => {
            setShowErrorToast(false);
          }, 3000);
        }
      );
  };

  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className="py-3 px-3 px-md-5">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Contacts</Breadcrumb.Item>
        </Breadcrumb>
        <Row className="border p-3 p-md-5 rounded bg-white">
          <Col className="col-12 col-lg-6">
            <h1 data-aos="fade-up" data-aos-delay="100">
              Contact Us
            </h1>
            <p data-aos="fade-up" data-aos-delay="200">
              Thank you for visiting our website! If you have any questions or inquiries, feel free
              to reach out to us using the contact information below.
            </p>
            <Row
              className="d-flex my-5 flex-wrap align-items-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <Col className="col-1">
                <FaLocationDot className="fs-1" />
              </Col>
              <Col className="col-11">
                <h6 className="mx-3 text-capitalize">
                  Room 1605, Ho King Commercial Centre,2-16 Fa Yuen Street,Mongkok, Kowloon, Hong
                  Kong
                </h6>
              </Col>
            </Row>
            <Row
              className="d-flex my-5 flex-wrap align-items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <Col className="col-1">
                <FaEnvelope className="fs-1" />
              </Col>
              <Col className="col-11">
                <h6 className="mx-3">inquiries@keepgoinggolf.com</h6>
              </Col>
            </Row>
            <Row
              className="d-flex my-5 flex-wrap align-items-center"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Col className="col-1">
                <FaPhone className="fs-1" />
              </Col>
              <Col className="col-11">
                <h6 className="mx-3">00852-61588111</h6>
              </Col>
            </Row>
            <Row
              className="d-flex my-5 flex-wrap align-items-center"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Col className="col-1">
                <FaFax className="fs-1" />
              </Col>
              <Col className="col-11">
                <h6 className="mx-3">00852-69917688</h6>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-lg-6">
            <div
              data-aos="fade-up"
              data-aos-delay="600"
              className="p-3 p-md-5 border border-3 border-black rounded bg-white"
            >
              <h2 className="fw-bold text-uppercase">Send us a message!</h2>
              <Form ref={form} onSubmit={sendEmail}>
                <Form.Group controlId="formName" className="my-2">
                  <Form.Label className="text-uppercase fw-bold">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_name"
                    className="border border-3 border-black"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="my-2">
                  <Form.Label className="text-uppercase fw-bold">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="user_email"
                    className="border border-3 border-black"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formMessage" className="my-2">
                  <Form.Label className="text-uppercase fw-bold">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    className="border border-3 border-black"
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="send-button text-uppercase fw-bold w-100 border-3 bg-black border-white"
                >
                  Send
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
      <Toast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "green",
          color: "white",
        }}
      >
        <Toast.Body>Message sent successfully!</Toast.Body>
      </Toast>
      <Toast
        show={showErrorToast}
        onClose={() => setShowErrorToast(false)}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <Toast.Body>Failed to send message. Please try again later.</Toast.Body>
      </Toast>
    </div>
  );
}

export default Contact;

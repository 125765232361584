import { useEffect } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Breadcrumb } from "react-bootstrap";
import React from "react";
function About() {
  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className="py-3 px-3 px-md-5">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>About</Breadcrumb.Item>
        </Breadcrumb>
        <Container className="border p-3 p-md-5 rounded bg-white">
          <h1 data-aos="fade-up">About Us</h1>
          <p data-aos="fade-up" data-aos-delay="100" className="justify">
            K&G Golf Fashion Co., Ltd. is a diverse high-class fashion sports apparel for golf that
            was established in the year 2016. As one of the Top 3 leading high-class fashion sports
            apparel and accessories for golf, K&G has been satisfying golf enthusiasts with comfort
            and fashion through its diverse golf products not just in the Philippines but also
            abroad. As we strive to be trusted golf wear, K&G Golf aims to continue to be the
            leading choice for golf apparel worldwide, recognized for our commitment to quality,
            style, and performance.
          </p>
          <h6 data-aos="fade-up" data-aos-delay="200">
            Mission
          </h6>
          <p data-aos="fade-up" data-aos-delay="300" className="justify">
            Our mission is to provide golfers with stylish, high-quality apparel that enhances their
            performance, confidence, and enjoyment on the course. We strive to blend innovation with
            tradition, offering versatile and functional designs that cater to the needs of golfers
            of all skill levels. Through our commitment to excellence and passion for the game, we
            aim to inspire confidence and elevate the golfing experience for our customers.
          </p>
          <h6 data-aos="fade-up" data-aos-delay="400">
            Vision
          </h6>
          <p data-aos="fade-up" data-aos-delay="500" className="justify">
            At K&G Golf Fashion Co., Ltd., our vision is to become the leading choice for golf
            apparel worldwide, recognized for our commitment to quality, style, and performance. We
            envision a community of passionate golfers who trust and rely on our brand to equip them
            with apparel that not only looks great but also enhances their game. We aspire to
            continuously innovate and expand our product offerings while maintaining our dedication
            to sustainability and ethical business practices. Ultimately, we seek to contribute to
            the growth and enjoyment of the game of golf while fostering a culture of excellence and
            inclusivity within the golfing community.
          </p>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}

export default About;

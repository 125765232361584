import { useEffect } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
function Error() {
  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <Container className="py-5 px-3 px-md-5">
        <Container className="p-3 p-md-5 text-center">
          <h4 data-aos="fade-up" className="error-text">
            404
          </h4>
          <h3 className="text-uppercase">Page not found</h3>
          <p className="mt-3">We're sorry, but the page you are looking for does not exist.</p>
          <p className="mt-3">
            Please check the URL or go back to the{" "}
            <Link to="/" className="text-black link-offset-2 fw-bold">
              homepage
            </Link>
            .
          </p>
        </Container>
      </Container>
      <Footer />
    </div>
  );
}

export default Error;

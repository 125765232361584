import { useEffect, useState } from "react";
import React from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Row, Col, Container, Breadcrumb, Button, Card, Spinner } from "react-bootstrap";
import { FaFaceFrown } from "react-icons/fa6";
function ProductDetails() {
  const { category, id } = useParams();
  const [product, setProduct] = useState(null);
  const [otherProducts, setOtherProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fething Product from ID
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/product.json");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const foundProduct = data.find((product) => product.id === parseInt(id));

        if (foundProduct && foundProduct.category === category) {
          setProduct(foundProduct);
          fetchOtherProductsData(data, foundProduct.category, parseInt(id));
        } else {
          console.error("Product not found");
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({});
    window.scrollTo(0, 0);
    setSelectedImageIndex(0);
  }, [category, id]);
  // End of Fetching Product from ID

  // Fetch other Products
  const fetchOtherProductsData = (data, category, currentProductId) => {
    const otherProductsData = data.filter(
      (product) => product.category === category && product.id !== currentProductId
    );

    const shuffledOtherProductsData = shuffleArray(otherProductsData);

    const selectedOtherProducts = shuffledOtherProductsData.slice(0, 4);

    setOtherProducts(selectedOtherProducts);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleButtonClick = (index) => {
    setSelectedImageIndex(index);
  };

  // End of fetching other products
  if (loading) {
    // Display loading indicator while data is loading
    return (
      <div className="d-flex w-100 justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <Container className="py-3">
        {product ? (
          <>
            <Breadcrumb className="my-2">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/products">Products</Breadcrumb.Item>
              <Breadcrumb.Item href={`/products/${product.category}`}>
                {product.category}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{product.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="border mx-auto p-3 bg-white rounded">
              <Col
                className="col-12 col-lg-6 d-flex justify-content-center flex-wrap"
                data-aos="fade-up"
              >
                <img
                  src={`/images/products/list/${product.images[selectedImageIndex]}`}
                  className="h-auto w-100"
                  alt={product.name}
                />
              </Col>
              <Col className="col-12 col-lg-6 my-5 my-md-0" data-aos="fade-up">
                <p className="text-black text-uppercase fs-2 fw-bolder my-0">{product.name}</p>
                <p className="text-secondary text-uppercase fs-6 fw-bolder my-0">{product.type}</p>
                <p className="product-description my-3">{product.description}</p>
                <p className="product-material text-uppercase my-0 py-1">
                  <span className="fw-bold">Materials: </span> {product.material.join(" , ")}
                </p>
                <p className="product-color text-uppercase my-0 ">
                  <span className="fw-bold">Colors: </span> {product.colors.join(" , ")}
                </p>
                <div className="py-1">
                  {product.hexcode.map((hexcode, index) => {
                    if (hexcode.includes("/")) {
                      const [color1, color2] = hexcode.split("/");
                      return (
                        <Button
                          key={index}
                          className="color-box p-0 border rounded-0 bg-transparent "
                          onClick={() => handleButtonClick(index)}
                          style={{ position: "relative" }}
                        >
                          <div
                            className="color-half position-absolute top-0 left-0 w-100 h-100"
                            style={{
                              backgroundImage: `linear-gradient(to bottom right, ${color1} 50%, ${color2} 50%)`,
                            }}
                          ></div>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          key={index}
                          className="color-box rounded-0 border"
                          style={{ backgroundColor: hexcode }}
                          onClick={() => handleButtonClick(index)}
                        ></Button>
                      );
                    }
                  })}
                </div>
                <p className="product-color text-capitalize my-0 py-1">
                  <span className="fw-bold">Sizes: </span> {product.sizes.join(", ")}
                </p>
              </Col>
              <Row className="my-3 pt-3">
                <Container>
                  <h5
                    className="fw-bolder text-decoration-underline link-offset-2"
                    data-aos="fade-up"
                  >
                    YOU MAY ALSO LIKE
                  </h5>
                  <Row>
                    {otherProducts.map((otherProduct) => (
                      <Col
                        key={otherProduct.id}
                        className="col-6 col-md-6 col-lg-3 p-2"
                        data-aos="fade-up"
                      >
                        <Link
                          to={`/products/${otherProduct.category}/${otherProduct.id}`}
                          className="product-card-link text-decoration-none"
                        >
                          <Card className="rounded-0 border">
                            <Card.Header className="d-flex justify-content-center border-0 rounded-0 p-0">
                              <Card.Img
                                variant="top"
                                src={`/images/products/list/${otherProduct.images[0]}`}
                                className="img-fluid h-auto"
                              />
                            </Card.Header>
                            <Card.Body>
                              <Card.Title className="product-name my-0">
                                {otherProduct.name}
                              </Card.Title>
                              <Card.Text className="product-text my-0">
                                {otherProduct.type}
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className="border-0 d-flex justify-content-center bg-white p-1 p-md-0">
                              <Link
                                to={`/products/${otherProduct.category}/${otherProduct.id}`}
                                className="product-button w-100 text-center p-1 rounded-0 text-lowercase text-decoration-none"
                              >
                                View
                              </Link>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Row>
            </Row>
          </>
        ) : (
          <div className="product-error p-3 my-5 text-center">
            <FaFaceFrown className="frown-icon w-100" />
            <p className="fs-5 w-100">Sorry, we couldn't find the product you're looking for.</p>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default ProductDetails;

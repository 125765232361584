import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../component/Footer";
import Header from "../component/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { Card, Col, Container, Row, Breadcrumb, Pagination, Spinner } from "react-bootstrap";
import { FaFaceFrown } from "react-icons/fa6";

function Product() {
  const { category, searchQuery } = useParams();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");

  // Start of Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setProductsPerPage(8); // Desktop
      } else if (window.innerWidth > 768) {
        setProductsPerPage(6); // Tablet
      } else {
        setProductsPerPage(6); // Mobile
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Calculate range of pages to display
  const visiblePages = 3; // Number of visible pages
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  // Adjust startPage if endPage is too close to totalPages
  if (endPage === totalPages && startPage > 1) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  // Adjust endPage if startPage is too close to 1
  if (startPage === 1 && endPage < totalPages) {
    endPage = Math.min(totalPages, startPage + visiblePages - 1);
  }
  // End of Pagination

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data/product.json");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        let filtered = data;

        if (category) {
          filtered = filtered.filter((product) => product.category === category);
        }

        if (searchQuery) {
          filtered = filtered.filter((product) =>
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }

        filtered.sort((a, b) => {
          if (sortOrder === "asc") {
            return a.type.localeCompare(b.type);
          } else {
            return b.type.localeCompare(a.type);
          }
        });

        setTimeout(() => {
          setFilteredProducts(filtered);
          setLoading(false);
        }, 1500);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchData();
    AOS.init({});
    window.scrollTo(0, 0);
  }, [category, searchQuery]);

  if (loading) {
    // Display loading indicator while data is loading
    return (
      <div
        className={`d-flex w-100 justify-content-center align-items-center vh-100 ${
          loading ? "" : "loading-spinner hidden"
        }`}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Container className="py-3">
        {filteredProducts.length === 0 ? (
          <div className="product-error p-3 my-5 text-center">
            <FaFaceFrown className="frown-icon w-100" />
            <p className="fs-5 w-100">Sorry, we couldn't find the product you're looking for.</p>
          </div>
        ) : (
          <>
            <Breadcrumb className="my-3">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/products" active={!category}>
                Products
              </Breadcrumb.Item>
              {category && <Breadcrumb.Item active>{category}</Breadcrumb.Item>}
            </Breadcrumb>
            <h1 className="fs-4 text-uppercase fw-bold text-decoration-underline link-offset-3">
              Products
            </h1>
            <Row className="d-flex flex-wrap d-flex ">
              {currentProducts.map((product, index) => (
                <Col
                  key={product.id}
                  className="col-6 col-md-4 col-lg-3 justify-content-start py-2"
                  data-aos="fade-up"
                  data-aos-delay={`${index * 100}`}
                >
                  <Link
                    to={`/products/${product.category}/${product.id}`}
                    className="product-card-link text-decoration-none"
                  >
                    <Card className="rounded-0 border">
                      <Card.Header className="d-flex justify-content-center border-0 rounded-0 p-0">
                        <Card.Img
                          variant="top"
                          src={`/images/products/list/${product.images[0]}`}
                          className="img-fluid h-auto"
                        />
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="product-name my-0">{product.name}</Card.Title>
                        <Card.Text className="product-text my-0">{product.type}</Card.Text>
                      </Card.Body>
                      <Card.Footer className="border-0 d-flex justify-content-center bg-white">
                        <Link
                          to={`/products/${product.category}/${product.id}`}
                          className="product-button w-100 text-center p-1 rounded-0 text-lowercase text-decoration-none"
                        >
                          View
                        </Link>
                      </Card.Footer>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
            <Pagination className="justify-content-center mt-3">
              {/* "First" button */}
              <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} />
              {/* "Previous" button */}
              <Pagination.Prev
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {/* Numbered pages */}
              {[...Array(endPage - startPage + 1).keys()].map((index) => {
                const pageNumber = startPage + index;
                return (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => paginate(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              })}
              {/* "Next" button */}
              <Pagination.Next
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              />

              {/* "Last" button */}
              <Pagination.Last
                onClick={() => paginate(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default Product;

import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const url = searchQuery ? `/products/search/${searchQuery}` : "/products";
    navigate(url);
    setShow(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-100">
      <p className="text-center bg-black text-white py-3 py-md-2 m-0 small md:fs-6">
        {/* Save ₱1000 on Your First Order! Use Code
        <span className="bg-white text-black py-1 px-2 mx-1 rounded fw-bold">RNDMK&GCODE</span>
        at Checkout. Expires April 1, 2024.
        <Link className="mx-1 text-uppercase text-white link-offset-2">Shop Now!</Link> */}
        Welcome to K&G Golf Apparel: Elevate Your Game in Style!
      </p>
      <Navbar expand="lg" className="border-bottom bg-white py-0">
        <Container>
          <Navbar.Brand className="py-0 nav-brand">
            <Nav.Link href="/">
              <img src="/images/kng-logo.jpg" alt="K&G Logo" className="brand-logo"></img>
            </Nav.Link>
          </Navbar.Brand>
          {!isMobile ? (
            <div>
              <Nav>
                <Nav.Item>
                  <Nav.Link href="/products" className="text-black text-uppercase fw-semibold">
                    All
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/products/men" className="text-black text-uppercase fw-semibold">
                    Men
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="/products/women"
                    className="text-black text-uppercase fw-semibold"
                  >
                    Women
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/products/kids" className="text-black text-uppercase fw-semibold">
                    Kids
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="/products/accessories"
                    className="text-black text-uppercase fw-semibold"
                  >
                    Accessories
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/products/bags" className="text-black text-uppercase fw-semibold">
                    Bags
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          ) : null}
          {!isMobile ? (
            <div>
              <Form className="d-flex" onSubmit={handleSearchSubmit}>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Form>
            </div>
          ) : null}
          {isMobile ? (
            <div>
              <Container>
                <Button
                  className="bg-white border border-1 border-dark-subtle text-black-50"
                  onClick={handleShow}
                >
                  <FaBars />
                </Button>
                <Offcanvas show={show} onHide={handleClose} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="text-uppercase fw-bold">
                      Keep on Going
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form className="d-flex" onSubmit={handleSearchSubmit}>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </Form>
                    <Nav className="pt-5">
                      <Nav.Item>
                        <Nav.Link href="/products" className="fs-5 fw-bold text-center">
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/products/men" className="fs-5 fw-bold text-center">
                          Men
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/products/women" className="fs-5 fw-bold text-center">
                          Women
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/products/kids" className="fs-5 fw-bold text-center">
                          Kids
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/products/accessories" className="fs-5 fw-bold text-center">
                          Accessories
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="/products/bags" className="fs-5 fw-bold text-center">
                          Equipment
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Offcanvas.Body>
                </Offcanvas>
              </Container>
            </div>
          ) : null}
        </Container>
      </Navbar>
    </div>
  );
}
export default Header;

import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { BsTwitterX, BsFacebook, BsInstagram, BsEnvelope, BsPhone, BsMap } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function Footer() {
  return (
    <Navbar className="bg-black">
      {/* <Container className="d-flex flex-wrap p-5 align-items-start w-100 justify-content-center ">
        <Row className="d-flex justify-content-center w-100 flex-column flex-md-row">
          <Col className="col-12 col-md-4">
            <div className="footer-categories d-flex flex-wrap py-2 py-md-0 w-100">
              <Nav className="d-flex flex-wrap  flex-column justify-content-start justify-content-md-center w-100">
                <h1 className="text-uppercase fs-4 fw-bold text-white w-100">Categories</h1>
                <Row className="d-flex justify-content-between w-100">
                  <Col className="col-12 col-lg-6">
                    <Nav.Item className="footer-link">
                      <Nav.Link
                        href="/products/men"
                        className="text-white text-uppercase fw-semibold  px-0"
                      >
                        Men
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col className="col-12 col-lg-6">
                    <Nav.Item className="footer-link">
                      <Nav.Link
                        href="/products/women"
                        className="text-white text-uppercase fw-semibold  px-0"
                      >
                        Women
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100">
                  <Col className="col-12 col-lg-6">
                    <Nav.Item className="footer-link">
                      <Nav.Link
                        href="/products/kids"
                        className="text-white text-uppercase fw-semibold  px-0"
                      >
                        Kids
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                  <Col className="col-12 col-lg-6">
                    <Nav.Item className="footer-link">
                      <Nav.Link
                        href="/products/accessories"
                        className="text-white text-uppercase fw-semibold  px-0"
                      >
                        Accessories
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between w-100">
                  <Col className="col-6 ">
                    <Nav.Item className="footer-link">
                      <Nav.Link
                        href="/products/bags"
                        className="text-white text-uppercase fw-semibold  px-0"
                      >
                        Equipment
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                </Row>
              </Nav>
            </div>
          </Col>
          <Col className="col-12 col-md-3">
            <div className="footer-link d-flex flex-wrap justify-content-start py-2 py-md-0 w-100">
              <div className="d-flex flex-wrap justify-content-start flex-column ">
                <div className="pb-4 d-flex flex-wrap flex-column">
                  <h1 className="text-uppercase fs-4 fw-bold text-white">Company Info</h1>
                  <Link to="/about" className="text-white text-decoration-none fs-6 py-1">
                    About Us
                  </Link>
                  <Link to="/contacts" className="text-white text-decoration-none fs-6 py-1">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12 col-md-3">
            <div className="footer-link d-flex flex-wrap justify-content-start py-2 py-md-0 w-100">
              <div className="d-flex flex-wrap justify-content-start flex-column ">
                <div className="pb-4 d-flex flex-wrap flex-column">
                  <h1 className="text-uppercase fs-4 fw-bold text-white">Help</h1>
                  <Link to="/faqs" className="text-white text-decoration-none fs-6 py-1">
                    FAQs
                  </Link>
                  <Link to="/terms" className="text-white text-decoration-none fs-6 py-1">
                    Terms and Conditions
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12 col-md-2">
            <div className="footer-link d-flex flex-wrap justify-content-start py-2 py-md-0">
              <div className="d-flex flex-wrap justify-content-start flex-column ">
                <div className="pb-4">
                  <h1 className="text-uppercase fs-4 fw-bold text-white">Socials</h1>
                  <div className="d-flex flex-wrap justify-content-start flex-row">
                    <Link
                      to="https://www.facebook.com/GolfKNG/"
                      target="blank"
                      className="text-white text-decoration-none pe-2 fs-5"
                      aria-label="Facebook"
                    >
                      <BsFacebook />
                    </Link>
                    <Link
                      to="https://www.instagram.com/golfkng/"
                      target="blank"
                      className="text-white text-decoration-none pe-2 fs-5"
                      aria-label="Instagram"
                    >
                      <BsInstagram />
                    </Link>
                    <Link
                      to="https://twitter.com/golfkng"
                      target="blank"
                      className="text-white text-decoration-none pe-2 fs-5"
                      aria-label="Twitter"
                    >
                      <BsTwitterX />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container> */}
      <Container className="d-flex flex-wrap p-5 w-100 justify-content-center align-items-start">
        <div className="footer-column d-flex flex-wrap flex-column py-2 py-md-0 mb-5 mb-md-3">
          <h1 className="text-uppercase fs-4 fw-bold text-white w-100">Categories</h1>
          <Link to="/products/men" className="text-white text-decoration-none fs-6 py-1">
            Men
          </Link>
          <Link to="/products/women" className="text-white text-decoration-none fs-6 py-1">
            Women
          </Link>
          <Link to="/products/kids" className="text-white text-decoration-none fs-6 py-1">
            Kids
          </Link>
          <Link to="/products/accessories" className="text-white text-decoration-none fs-6 py-1">
            Accessories
          </Link>
          <Link to="/products/bags" className="text-white text-decoration-none fs-6 py-1">
            Bags
          </Link>
        </div>
        <div className="footer-column d-flex flex-wrap flex-column py-2 py-md-0 mb-5 mb-md-3">
          <h1 className="text-uppercase fs-4 fw-bold text-white w-100">Company Info</h1>
          <Link to="/about" className="text-white text-decoration-none fs-6 py-1">
            About Us
          </Link>
          <Link to="/contacts" className="text-white text-decoration-none fs-6 py-1">
            Contact Us
          </Link>
          <Link to="/faqs" className="text-white text-decoration-none fs-6 py-1">
            FAQs
          </Link>
          <Link to="/terms" className="text-white text-decoration-none fs-6 py-1">
            Terms and Conditions
          </Link>
        </div>
        <div className="footer-column mb-5 mb-md-3">
          <h1 className="text-uppercase fs-4 fw-bold text-white w-100">Follow Us</h1>
          <div className="d-flex flex-wrap justify-content-center align-items-center my-1 text-white">
            <div className="d-flex flex-wrap flex-column w-100">
              <div className="d-flex align-items-center">
                <div className="fs-4 me-3">
                  <BsFacebook />
                </div>
                <Link
                  target="none"
                  to="https://www.facebook.com/GolfKNG/"
                  className="d-flex small align-items-center mb-0 text-white text-decoration-none"
                >
                  www.facebook.com/GolfKNG
                </Link>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="fs-4 me-3">
                  <BsTwitterX />
                </div>
                <Link
                  target="none"
                  to="https://www.twitter.com/golfkng"
                  className="d-flex small align-items-center mb-0 text-white text-decoration-none"
                >
                  www.twitter.com/golfkng
                </Link>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="fs-4 me-3">
                  <BsInstagram />
                </div>
                <Link
                  target="none"
                  to="https://www.instagram.com/golfkng/"
                  className="d-flex small align-items-center mb-0 text-white text-decoration-none"
                >
                  www.instagram.com/golfkng
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-column mb-5 mb-md-3">
          <h1 className="text-uppercase fs-4 fw-bold text-white w-100">Contact Us</h1>
          <p className="text-white py-1 small">
            Thank you for visiting our website! If you have any questions or inquiries, feel free to
            reach out to us using the contact information below.
          </p>
          <div className="d-flex flex-wrap justify-content-center align-items-center my-1 text-white">
            <div className="d-flex flex-wrap flex-column w-100">
              <div className="d-flex align-items-center">
                <div className="fs-4 me-3">
                  <BsEnvelope />
                </div>
                <p className="d-flex small align-items-center mb-0">inquiries@keepgoinggolf.com</p>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="fs-4 me-3">
                  <BsMap />
                </div>
                <p className="d-flex small align-items-center mb-0 text-wrap">
                  Room 1605, Ho King Commercial Centre,2-16 Fa Yuen Street, Mongkok, Kowloon, Hong
                  Kong
                </p>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="fs-4 me-3">
                  <BsPhone />
                </div>
                <p className="d-flex small align-items-center mb-0">00852-61588111</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
export default Footer;
